.SearchSelect {
  width: 600px;
  height: 60px;
  position: relative;

  &__input {
    font-family: "Fredoka One";
    background-color: $green;
    color: $white;
    font-size: 14px;
    width: 100%;
    height: 100%;
    box-shadow: none;
    border: none;
    outline: none;
    padding: 0 17px;

    @include breakpoint($sm) {
      font-size: 18px;
      padding: 0 30px;
    }
  }

  &__options {
    width: 100%;
    top: 70px;
    position: absolute;

    &__option {
      cursor: pointer;
      font-size: 14px;
      background-color: $green;
      color: $white;
      padding: 10px;
      margin-bottom: 4px;

      @include breakpoint($sm) {
        font-size: 18px;
        padding: 20px;
      }

      &--isSelected {
        background-color: darken($green, 10%);
      }
    }
  }

  ::-webkit-input-placeholder {
    color: $white;
  }

  ::-moz-placeholder {
    color: $white;
  }

  :-ms-input-placeholder {
    color: $white;
  }

  :-moz-placeholder {
    color: $white;
  }

  // iOS input zoom fuck off.
  /* stylelint-disable */
  @media screen and (-webkit-min-device-pixel-ratio: 0) {

    select,
    textarea,
    input {
      font-size: 16px;
    }
  }

  /* stylelint-enable */
}